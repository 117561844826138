// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-codea-js": () => import("./../../../src/pages/codea.js" /* webpackChunkName: "component---src-pages-codea-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landingpagea-js": () => import("./../../../src/pages/landingpagea.js" /* webpackChunkName: "component---src-pages-landingpagea-js" */),
  "component---src-pages-landingpageb-js": () => import("./../../../src/pages/landingpageb.js" /* webpackChunkName: "component---src-pages-landingpageb-js" */),
  "component---src-pages-landingpagec-js": () => import("./../../../src/pages/landingpagec.js" /* webpackChunkName: "component---src-pages-landingpagec-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-resultsa-js": () => import("./../../../src/pages/resultsa.js" /* webpackChunkName: "component---src-pages-resultsa-js" */),
  "component---src-pages-resultsb-js": () => import("./../../../src/pages/resultsb.js" /* webpackChunkName: "component---src-pages-resultsb-js" */),
  "component---src-pages-resultsc-js": () => import("./../../../src/pages/resultsc.js" /* webpackChunkName: "component---src-pages-resultsc-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */)
}

